import { Message } from "../components/MessageList";
import axios, { AxiosResponse } from "axios";
import _ from 'lodash';
// import { ChatCtx, iChat } from "../context/ChatContext";
import { iChat } from "../@types/chatInfo";
import { ChatContextType, } from "../@types/chatInfo";

const endpointURL = "https://quelliv-chatbot-qa.shiftpixy.dev"
//const endpointURL = "/api"

export interface ChatMessageCtx {
  chatContext: ChatContextType;
  messages: Message[];
}

export interface SaveToDatabaseResponse {
  status: boolean;
  message: string;
}

export interface InitializeResponse {
  ok: boolean;
  start_prompt?: string;
  intro_ai_message?: string;
  start_sequence?: string; // Replace `any` with the correct type of `start_sequence`
  restart_sequence?: string; // Replace `any` with the correct type of `restart_sequence`
  chatroom_title?: string; // Replace `any` with the correct type of `restart_sequence`
  error?: string;
}


export const DatabaseService = {
  async saveConversation(uuid: string): Promise<SaveToDatabaseResponse> {
    const result = await saveToDatabase(uuid);
    return result;
  },
  async submitFeedback(chatMessageCtx: ChatMessageCtx,feedback : boolean): Promise<SaveToDatabaseResponse> {
    const result = await saveToDatabaseWithFeedback(chatMessageCtx,feedback);
    return result;
  }
}

export const InitService = {
  async getAppInit(uuid: string): Promise<InitializeResponse> {
    const result = await fetchInitialize(uuid);
    return result;
  }
}

async function saveToDatabaseWithFeedback(chatMessageCtx: ChatMessageCtx,feedback : boolean): Promise<SaveToDatabaseResponse> {
  try {
    const data = {
      messages:chatMessageCtx.messages,
      feedback:feedback,
    };
    const response = await axios.post(endpointURL+'/feedback', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = response.data;
    return {
      status: true,
      message: responseData.message
    };
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: 'Error occurred while saving data to the database'
    };
  }
}

async function saveToDatabase(uuid: string): Promise<SaveToDatabaseResponse> {
  try {
    const data = {
      uuid:uuid,
    };
    const response = await axios.post(endpointURL+'/save_chat_history_to_mongo', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = response.data;
    return {
      status: true,
      message: responseData.message
    };
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: 'Error occurred while saving data to the database'
    };
  }
}

async function fetchInitialize(uuid: string): Promise<InitializeResponse> {
  try {
    const data = {
      uuid:uuid,
    };
    const response = await axios.post(endpointURL+'/initialize', data,{
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log(response);
    const returnData = response.data;

    if (returnData.ok) {
      return {
        ok: true,
        start_prompt: returnData.start_prompt,
        intro_ai_message: returnData.intro_ai_message,
        start_sequence: returnData.start_sequence,
        restart_sequence: returnData.restart_sequence,
        chatroom_title: returnData.chatroom_title
      };
    } else {
      return {
        ok: false,
        error: returnData.error
      };
    }
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      error: 'Error has occurred with gpt service'
    };
  }
}

const GPTService = {
  /**
   * It takes a chat message, gets the prompt, gets the completion, and returns the completion
   * @param {ChatMessageCtx} chat - The chat message context.
   * @returns A string.
   */
  async getAIResponse(chat: ChatMessageCtx, uuid: string): Promise<string> {
      const prompt = getPrompt(chat);
      const result = await getCompletion(prompt, uuid);
      return result;
  },
};

export function trimLinesHelper(additional: number, lines: string[], hardMax: number): string[] {
  let characterCount = additional;
  const trimmedLines = _.takeRightWhile(lines, (line) => {
    characterCount += line.length;
    return characterCount <= hardMax;
  });
  return trimmedLines;
}

function trimLines(additional: number, lines: string[]): string[] {
  // As the chat continues, there's a tradeoff:
  // More lines == higher cost + better result
  // 2048 - 300 is upper bound for tokens.
  // We will assume 1 token ~= 4 characters and keep a window of ~500 tokens.
  const maxPromptLength = 500 * 4;
  // Davinci costs 0.06 per 1k tokens, so this is roughly 3 cents per completion at the upper end.
  return trimLinesHelper(additional, lines, maxPromptLength);
}

// For now we are only returning the last message submitted by the user since the server is tracking conversation history.
function getPrompt(chat:ChatMessageCtx): string {
  const latest_message = chat.messages[chat.messages.length - 1];
  return latest_message.message;
}
// IMPORTANT: Please only use this for local testing. If you are deploying
// your app onto the internet, you should route requests through your own
// backend server to avoid exposing your OpenAI API key in your client
// side code.
async function getCompletion(input: string, uuid: string): Promise<string> {
  let text: string = "";
  const data = {
    input:input,
    uuid:uuid,
  };
  try {
    const response = await axios.post(endpointURL, data);
    text = response.data.data;
    console.log('text : ' + text)
    if(text == undefined){
      // TODO Raise no response error and retry on client side. should engage user since they are waiting patiently.
      console.log("No response...");
      return "";
    }
    return text;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export default GPTService;


export interface CompletionsReturn {
  "id": string,
  "object": string,
  "created": number,
  "model": string,
  "choices": [
    {
      "text": string,
      "index": number,
      "logprobs": any,
      "finish_reason": string
    }
  ],
  "usage": {
    "prompt_tokens": number,
    "completion_tokens": number,
    "total_tokens": number
  }
}


const errorCompletionReturn: CompletionsReturn =
{
  id: "-1",
  object: "",
  created: 0,
  model: "",
  choices: [{
    "text": "",
    "index": -1,
    "logprobs": "error",
    "finish_reason": "error"
  }],
  usage: {
    prompt_tokens: 0,
    completion_tokens: 0,
    total_tokens: 0
  }
}
